import { create } from 'zustand';

// import { shallow } from 'zustand/shallow';

interface IState {
  info: API.UserInfoResponse | undefined;
  logined: boolean | undefined;
}

interface IAction {
  login: (info: IState['info']) => void;
  logout: () => void;
}

const initialValues: IState = {
  info: undefined,
  logined: undefined,
};

// 用户相关内容
const useUserStore = create<IState & IAction>((set) => {
  const login = (info: IState['info']) => {
    set({ info, logined: true });
  };

  const logout = () => {
    set({ info: undefined, logined: false });
  };

  return {
    ...initialValues,

    login,
    logout,
  };
});

export const useUserInfo = () => useUserStore(({ info }) => info);

export const useLogined = () => useUserStore(({ logined }) => logined);

export default useUserStore;
