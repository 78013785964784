import service from './http';

// 用户代理邀请码
// @deprecated
// Todo: 新版本更换了
export const GetFeloSubtitleInviteCode = () =>
  service.request({
    method: 'post',
    url: '/user/proxy/felo.subtitle.invite.code.gen',
  });

// 获取用户邀请列表
// @deprecated
// Todo: 新版本更换了
export const GetSubtitleInviteList = (current: Number) =>
  service.request({
    method: 'get',
    url: '/user/proxy/felo.subtitle.invite.list',
    data: {
      current,
      size: 1000,
    },
  });
// 供应商&管理员-查询自己的员工信息
// @deprecated
export const querySelfInfo = () =>
  service.request({
    method: 'post',
    url: '/proxy/query.self.info',
  });
// 查询团队计划
// @deprecated
export const GetTeamPlan = (team_id: string) =>
  service.request({
    method: 'get',
    url: '/team.subscription.detail',
    params: {
      team_id,
    },
  });
// 获取用户是否激活的信息
// @deprecated
export const GetUserActivity = () =>
  service.request({
    method: 'get',
    url: '/user/proxy/felo.subtitle.query.user.info',
  });
// 获取用户奖励的时间
// @deprecated
// Todo: 新版本更换了
export const GetRewardCount = () =>
  service.request({
    method: 'get',
    url: '/user/proxy/felo.subtitle.reward.count',
  });
// 根据邀请码查询用户
// @deprecated
export const QueryUserInfoByInviteCode = (inviteCode: string) =>
  service.request({
    method: 'post',
    url: '/liberty/user/proxy/felo.subtitle.query.user.info.by.invite.code?inviteCode=' + inviteCode,
  });
// 查询当前团队的增值包列表
// @deprecated
// Todo: 新版本更换了
export const getTeamPlanList = (current: number, size: number) =>
  service.request({
    method: 'get',
    url: '/team.plan.list?size=' + size + '&current=' + current,
  });
// 查询当前团队的增值包余量
// @deprecated
// Todo: 新版本更换了
export const getTeamPlanAmount = () =>
  service.request({
    method: 'get',
    url: '/team.plan.amount?itemType=MEET_SUBTITLE',
  });
// 查询当前用户的使用记录
// @deprecated
// Todo: 新版本更换了
export const getUsagelogList = (current: number, size: number) =>
  service.request({
    method: 'get',
    url: '/usage.log.list?itemType=MEET_SUBTITLE&size=' + size + '&current=' + current,
  });

// 查询用户信息
export const getUserInfo = () => (
  service.request<API.Response<API.UserInfoResponse>>({
    method: 'get',
    url: '/api/user',
  })
);

// Code 转 Token
export const parseCode = (code: string) => service.request<API.Response<string>>({
  method: 'get',
  url: '/api/user/token.code.parse',
  params: {
    appId: 'subtitle',
    code,
  },
});

// 退出登录
export const signOut = () => (
  service.request({
    method: 'post',
    url: '/api/user/sign.out',
  })
);
