import { useTranslation } from 'react-i18next';
import { Table, Avatar, message } from 'antd';
import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import type { ColumnsType } from 'antd/es/table';
import { copyText } from '@/utils/common';
import { useAuthContext } from '@/hooks/auth';
import Header from '@/components/layout/front/header';
import Footer from "../Footer/footer"
import FeloLogo from '@/assets/images/image 6.svg';
import InviteIcon from '@/assets/images/invite.svg';

import './index.less';
import { getInviteCode,getInvitationPage,getInvitationInfo,getUserName } from '@/api';

function FeloInvite() {
  const { t } = useTranslation();
  const { state, skiplogin } = useAuthContext();
  const { isLogin, user } = state;
  const [data, setData] = useState<any>([]);
  const [curr, setCurr] = useState(1);
  const size = 100;
  const [total, setTotal] = useState(0);
  const [surplusTime, setSurplusTime] = useState(0);
  const [inviteCodeUrl, setInviteCodeUrl] = useState('');
  const [sendTime,setSendTime] = useState<number>(0);
  useEffect(() => {
    if (!isLogin) {
      return;
    }
    if (!user) {
      return;
    }
    try {
      getInviteCode().then((res) => {
        const url = new URL(window.location.origin);
        url.searchParams.append('code', res.data.data);
        setInviteCodeUrl(url.toString());
      });
      getInvitationPage(curr,size).then((res) => {
        if(res.data.data.records){
          for (let i = 0; i < res.data.data.records?.length; i++) {
            res.data.data.records[i].gmt_first_login = dayjs(res.data.data.records[i].gmt_first_login).format(
              'YYYY-MM-DD HH:mm:ss',
            );
          }
        }
        if(res.data.data.records){
          setData(res.data.data.records);
        }
        else{
          setData([]);
        }
        setTotal(res.data.data.total);
        setCurr(res.data.data.current);
      });
      getInvitationInfo().then((res) => {
        if (res.data.data?.durations) {
          setSendTime(res.data.data.durations/60);
        }
        if (res.data.data?.total) {
          setSurplusTime(res.data.data.total/60);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [isLogin, user]);

  const copyClick = () => {
    copyText(inviteCodeUrl, () => {
      message.success(t('copySuccess'));
    });
  };

  const columns: ColumnsType<unknown> = [
    {
      title: t('friends'),
      dataIndex: 'invitee_name',
      onCell: () => ({
        style: {
          maxWidth: 150,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          cursor: 'pointer',
        },
      }),
    },
    {
      title: t('firstLoginTime'),
      dataIndex: 'gmt_first_login',
    },
  ];

  return (
    <div className="invite-background">
      <Header />
      <div className="invite-info">
        <div className="invite-header">
          <div className="invite-user-info" style={{ display: isLogin ? 'none' : 'flex' }}>
            <div className="invite-user-content">
              <Avatar className="avatar-icon" size={40} src={FeloLogo} />
              <div className="user-button-text" onClick={skiplogin} style={{ marginLeft: '8px' }}>
                {t('SignIn')}
              </div>
            </div>
            <div className="bottom-color"></div>
          </div>
          <div className="invite-user-info" style={{ display: isLogin ? 'flex' : 'none' }}>
            <div className="invite-user-content">
            <Avatar
                className="avatar-icon"
                style={{ backgroundColor: user?.picture?.indexOf('http') >= 0 ? 'transparent' : 'rgb(36, 141, 247)' }}
                size={40}
                src={user?.picture?.indexOf('http') >= 0 ? user.picture : null}
                >
                {user?.email?.charAt(0)}
              </Avatar>
              <div className="user-text">{user?.account_info?.display_name}</div>
              <div className="user-text" style={{ marginLeft: '20px' }}>
                {t('awards', { time: surplusTime })}
              </div>
            </div>
            <div className="bottom-color"></div>
          </div>
          <div className="model-center">
            <div className="invite-title-content">
              <div className="invite-modal-title">{t('invitation')}</div>
              <img className="invite-icon" src={InviteIcon} />
            </div>
            <div className="modal-text">{t('earnTime',{time:sendTime})}</div>
            <div className="modal-text">{t('earnMore')}</div>
          </div>
          <div className="step-invite-content">
            <div className="step-invite-item">
              <div className="item-number">01.</div>
              <div className="step-item">{t('firstStep')}</div>
              <div className="step-content">{t('shareLink')}</div>
            </div>
            <div className="step-invite-item">
              <div className="item-number">02.</div>
              <div className="step-item">{t('secondStep')}</div>
              <div className="step-content">{t('friendsCreate')}</div>
            </div>
            <div className="step-invite-item">
              <div className="item-number">03.</div>
              <div className="step-item">{t('thirdStep')}</div>
              <div className="step-content">{t('installExtension')}</div>
            </div>
            <div className="step-invite-item">
              <div className="item-number">04.</div>
              <div className="step-item">{t('fourthStep')}</div>
              <div className="step-content">{t('earnRewards')}</div>
            </div>
          </div>
          <div className="model-center" style={{ display: isLogin ? 'flex' : 'none' }}>
            <div className="modal-link-text">{t('invitationLink')}</div>
            <div className="sharp-content">
              <div className="sharp-url">
                <div className="url-text">{inviteCodeUrl}</div>
              </div>
              <div className="sharp-button" onClick={copyClick}>
                {t('Copy')}
              </div>
            </div>
          </div>
        </div>

        <div className="table-data-content" style={{ display: isLogin ? 'block' : 'none' }}>
          <div className="invitation-text">{t('invitationRecords')}</div>
          <div className="table-content">
            <Table
              pagination={false}
              columns={columns}
              dataSource={data}
              className="table-details"
              locale={{ emptyText: t('NoData') }}
              bordered={false}
              rowClassName={(record, index) => {
                let className = '';
                className = index % 2 === 0 ? 'ou' : 'ji';
                // console.log(className)
                return className;
              }}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default FeloInvite;
