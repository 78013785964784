import qs from 'qs';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import langTW from '../locales/zhTW.json';
import langEN from '../locales/enUS.json';
import langJP from '../locales/jaJP.json';
import langCN from '../locales/zhCN.json';
import { parseTime } from '.';

const getFirstBrowserLanguage = () => {
  const nav = window.navigator;
  const browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'];
  let i;
  let language;

  // support for HTML 5.1 "navigator.languages"
  if (Array.isArray(nav.languages)) {
    for (i = 0; i < nav.languages.length; i += 1) {
      language = nav.languages[i];
      if (language && language.length) {
        return language;
      }
    }
  }

  // support for other well known properties in browsers
  for (i = 0; i < browserLanguagePropertyKeys.length; i += 1) {
    language = nav[browserLanguagePropertyKeys[i]];
    if (language && language.length) {
      return language;
    }
  }

  return null;
};

const normalizeLanguage = (language: string) => {
  const langCode = language.toLowerCase();

  if (langCode.startsWith('ja')) {
    return 'ja';
  }

  if (langCode.indexOf('cn') >= 0) {
    return 'zh-CN';
  }
  if (langCode.indexOf('tw') >= 0) {
    return 'zh-TW';
  }

  return 'en';
};

const resources = {
  'zh-TW': {
    translation: langTW,
  },
  en: {
    translation: langEN,
  },
  ja: {
    translation: langJP,
  },
  'zh-CN': {
    translation: langCN,
  },
};

const getLanguage = () => {
  const { search } = window.location;

  let lang = '';

  if (search) {
    const parseObj = qs.parse(search, { ignoreQueryPrefix: true }) as { lang?: string; state?: string };

    if (parseObj && parseObj.lang) {
      lang = parseObj.lang;
    }
  }

  lang = normalizeLanguage(getFirstBrowserLanguage());

  return lang;
};

// 格式化的时间
export const localizeTime = (seconds: number) => {
  const [min, sec] = parseTime(seconds);

  if (min) {
    return `${i18n.t('minutes', { time: min })} ${i18n.t('seconds', { time: sec })}`;
  }

  return i18n.t('seconds', { time: sec });
};

const defaultLang = getLanguage();
console.log(defaultLang);
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: defaultLang,
    fallbackLng: defaultLang,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

document.documentElement.lang = i18n.language;
document.documentElement.classList.add(i18n.language);
window.sessionStorage.setItem('lang', i18n.language);
i18n.on('languageChanged', (lng) => {
  window.sessionStorage.setItem('lang', lng);

  document.documentElement.setAttribute('lang', lng);
  document.documentElement.classList.remove('zh-TW', 'ja', 'ja-JP', 'en', 'en-US');
  document.documentElement.classList.add(lng);
});

export default i18n;
