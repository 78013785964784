import uap from 'ua-parser-js';

import useUserStore from '@/stores/user';

const { VITE_LOG_URI } = import.meta.env;

interface DataObject {
  browser_language: string;
  browser_type?: string;
  browser_version?: string;
  device_id?: string;
  event_time: number;
  ext: string;
  model?: string;
  os_type?: string;
  report_application: string;
  report_application_version?: string;
  timezone: string;
  user_id?: string;
}

const ua = uap();

export default async function trackEvent(event: any) {
  // 浏览器语言
  const browserLanguage = navigator.language;

  // 系统时区
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const url = `${VITE_LOG_URI}/api/data/report`;

  const data: DataObject = {
    browser_language: browserLanguage,
    browser_type: ua.browser.name,
    browser_version: ua.browser.major,
    device_id: undefined,
    event_time: Date.now(),
    ext: JSON.stringify(event),
    os_type: ua.os.name,
    // 插件版是 subtitle-plugin
    // PC 版是 subtitle-desktop
    // 官网是 subtitles-home
    report_application: 'subtitles-home',
    report_application_version: undefined,
    timezone: timeZone,
    user_id: useUserStore.getState().info?.uid,
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    });

    if (response.ok) {
      // console.log('mark success', await response.json(), JSON.stringify(data));
    } else {
      console.log('mark error', await response.text());
    }
  } catch (error) {
    console.log('mark error', error);
  }
}
