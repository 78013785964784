import { createContext, Dispatch } from 'react';
import { OAuthTokenData, UserDetailInfo } from '@/types';

export interface AuthState {
  loading: boolean;

  error: string | undefined;
  user: any;
  isLogin: boolean;
}

export const initialState: AuthState = {
  loading: true,
  error: undefined,
  user: undefined,
  isLogin: false,
};

export enum ActionKind {
  SET_LOADING,
  SET_ERROR,
  LOGIN,
  LOGOUT,
  UPDATE_USER,
  IS_LOGIN,
  SET_ACCOUNTLIST,
}

export type Action =
  | {
      type: ActionKind.SET_LOADING;
      payload: boolean;
    }
  | {
      type: ActionKind.SET_ACCOUNTLIST;
      payload: Array<any>;
    }
  | {
      type: ActionKind.IS_LOGIN;
      payload: boolean;
    }
  | {
      type: ActionKind.SET_ERROR;
      payload: Error | undefined;
    }
  | {
      type: ActionKind.LOGIN;
      payload: {
        user: any;
      };
    }
  | {
      type: ActionKind.LOGOUT;
    }
  | {
      type: ActionKind.UPDATE_USER;
      payload: {
        user: any;
      };
    };

export const AuthContext = createContext<{
  state: AuthState;
  dispatch: Dispatch<Action>;
  login: () => Promise<void>;
  logout: () => void;
  skiplogin: () => void;
}>({
  state: initialState,
  dispatch: () => null,
  login: () => Promise.resolve(),
  logout: () => null,
  skiplogin: () => null,
});
