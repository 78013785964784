import { Avatar, Dropdown, Menu } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';
import Cookies from 'js-cookie';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useAuthContext } from '@/hooks/auth';
import inviteTimeIcon from '@/assets/images/inviteTime.svg';
import SvgIcon from '@/components/SvgIcon';
import { signOut, getUserInfo } from '@/api/api';

import './index.less';

function UserMenu() {
  const { state, skiplogin } = useAuthContext();
  const { t } = useTranslation();
  const { isLogin, user } = state;

  useEffect(() => {
    ReactGA.initialize('G-ME7K3JP1NG');
    ReactGA.send({ hitType: 'pageview', page: 'felo subtitle', page_url: window.location.href });
  }, []);

  const getUserInfoFunc = async () => {
    try {
      console.log(document.visibilityState);
      console.log(isLogin);
      if (document.visibilityState === 'visible' && isLogin) {
        const info = await getUserInfo();
        console.log(info);
      }
    } catch (error) {
      console.log(error);

      Cookies.remove('subtitlestoken');
      Cookies.remove(import.meta.env.VITE_COOKIE_SUFFIX);
      window.location.reload();
    }
  };

  useEffect(() => {
    window.addEventListener('visibilitychange', getUserInfoFunc);
    return () => {
      window.removeEventListener('visibilitychange', getUserInfoFunc);
    };
  }, []);

  const menuItems: ItemType[] = [
    {
      key: 'account',
      icon: <SvgIcon name="user-rounded" className="menu-icon" />,
      label: (
        <a href={`${import.meta.env.VITE_FELO_USER_URL}/account`}  className="subtitle-menu-item">
          <div className="menu-item-name">{t('myAccount')}</div>
        </a>
      ),
    },
    {
      key: 'invite',
      icon: <SvgIcon name="gift" className="menu-icon" />,
      label: (
        <Link to="/invite" className="subtitle-menu-item">
          <div className="menu-item-name">{t('invitingReward')}</div>
        </Link>
      ),
    },
    {
      key: 'log',
      icon: <SvgIcon name="subtitle-log" className="menu-icon" />,
      label: (
        <a href={`${import.meta.env.VITE_FELO_USER_URL}/record`} className="subtitle-menu-item">
          <div className="menu-item-name">{t('subtitleLog')}</div>
        </a>
      ),
    },
    {
      key: 'logout',
      icon: <SvgIcon name="logout" className="menu-icon" />,
      label: (
        <div className="subtitle-menu-item">
          <div className="menu-item-name">{t('logOut')}</div>
        </div>
      ),
      onClick: async () => {
        await signOut();

        Cookies.remove('subtitlestoken');
        Cookies.remove(import.meta.env.VITE_COOKIE_SUFFIX);
        window.location.reload();
      },
    },
  ];

  if (isLogin) {
    return (
      <Dropdown overlay={<Menu items={menuItems} />} overlayClassName="dropdown-custom" placement="bottomRight">
        <Avatar
          className="felo-subtitle-avatar"
          style={{ backgroundColor: user?.picture?.indexOf('http') >= 0 ? 'transparent' : 'rgb(36, 141, 247)' }}
          size={53}
          src={user?.picture?.indexOf('http') >= 0 ? user.picture : null}
        >
          {user?.email?.charAt(0).toLocaleUpperCase()}
        </Avatar>
      </Dropdown>
    );
  }

  return (
    <div className="title-login-button" onClick={skiplogin}>
      {t('SignIn')}
    </div>
  );
}

export default UserMenu;
