import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuthContext } from '@/hooks/auth';
import Introduction from '../introduction';
import { getUserName,getInvitationInfo } from '@/api';
import Header from '@/components/layout/front/header';
// import Footer from '@/components/layout/front/footer';
import Footer from "../Footer/footer"
import './index.less';

const { VITE_FELO_ID_URL } = import.meta.env;

function Home() {
  const { t } = useTranslation();
  const { state } = useAuthContext();
  const { isLogin } = state;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [inviteName, setInviteName] = useState<string | null>('');
  const [inviteCode, setInviteCode] = useState<string | null>('');
  const [sendTime,setSendTime] = useState<number>(0);
  useEffect(() => {
    const code = searchParams.get('code');
    if (!isLogin && code) {
      getInvitationInfo().then((res) => {
        if (res.data.data?.durations) {
          setSendTime(res.data.data.durations/60);
        }
      });
      getUserName(code).then((res) => {
        if (res.data.data) {
          setInviteName(res.data.data);
          setInviteCode(searchParams.get('code'));
          setIsModalOpen(true);
        }
      });
    }
  }, [isLogin]);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const createAccount = () => {
    const url = new URL(`${VITE_FELO_ID_URL}/register`);
    url.searchParams.append('app_id', 'subtitle');
    url.searchParams.append('redirect_uri', window.location.origin);

    if (inviteCode) {
      url.searchParams.append('invite', inviteCode);
    }
    window.location.href = url.toString();
  };

  return (
    <div className="login-background">
      <Header />
      <Introduction />
      <Footer />

      <Modal
        className="modal-invite"
        maskClosable={false}
        title=""
        footer={null}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="modal-invite-content">
          <div className="modal-invite-header">
            <div className="modal-invite-title">{t('invitesUserFelo', { Username: inviteName })}</div>
            <div className="modal-invite-text" dangerouslySetInnerHTML={{ __html: t('givesMinutes', { time: sendTime }) }}></div>
          </div>
          <div className="modal-home-button" onClick={createAccount}>
            {t('createAccount')}
          </div>
          <div className="moadl-size-text">{t('installation')}</div>
          <div className="modal-invite-buttom">{t('transcribeAndTranslate')}</div>
        </div>
      </Modal>
    </div>
  );
}

export default Home;
