import { PropsWithChildren, Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuthState } from '@/hooks/auth';
import { getLocale } from '@/utils';

const Spin = () => (
  <div className="loading">
    <div className="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);

export function MainEntry({ children }: PropsWithChildren<unknown>) {
  const { user } = useAuthState();

  const { i18n } = useTranslation();

  const langeForBaseUI = getLocale(user?.profile?.lang || i18n.language);

  useEffect(() => {
    if (langeForBaseUI && langeForBaseUI !== i18n.language) {
      window.sessionStorage.setItem('lang', langeForBaseUI);

      i18n.changeLanguage(langeForBaseUI);
    }
  }, [i18n, langeForBaseUI]);

  return children;
}

export function MainSpin({ children }: PropsWithChildren<unknown>) {
  const { t } = useTranslation();
  const authState = useAuthState();

  const isLoading = authState.loading;

  if (isLoading) {
    return <Spin />;
  }

  return <>{children}</>;
}

export function MainLayout() {
  return (
    <MainEntry>
      <MainSpin>
        <Suspense fallback={'...'}>
          <Outlet />
        </Suspense>
      </MainSpin>
    </MainEntry>
  );
}
