import type { CSSProperties, MouseEvent } from 'react';

interface SvgIconProps {
  name: string;
  className?: string;
  style?: CSSProperties;
  onClick?: (ev: MouseEvent) => void;
  onMouseEnter?: (ev: MouseEvent) => void;
  onMouseLeave?: (ev: MouseEvent) => void;
}

function SvgIcon({ name, ...props }: SvgIconProps) {
  return (
    <svg {...props}>
      <use xlinkHref={`/__spritemap#sprite-${name}`}></use>
    </svg>
  );
}

export default SvgIcon;
