import { PropsWithChildren, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { OrganizationUserDetailInfo, UserDetailInfo } from '@/types';
import { AuthContext, AuthState, initialState, Action, ActionKind } from './Context';
import { getUserInfo, parseCode } from '@/api/api';
import { getCurrentPlan } from '@/api';
import { useEvent } from '@/hooks/useEvent';
import useUserStore from '@/stores/user';

const { VITE_FELO_ID_URL } = import.meta.env;

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case ActionKind.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ActionKind.IS_LOGIN:
      return {
        ...state,
        isLogin: action.payload,
      };
    case ActionKind.LOGIN:
      return {
        ...state,
        user: action.payload.user,
      };
    case ActionKind.LOGOUT:
      return {
        ...initialState,
      };
    case ActionKind.UPDATE_USER:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};

export const makeUserInfo = (
  userInfo: any,
  accountInfo: OrganizationUserDetailInfo,
): UserDetailInfo => ({
  openId: accountInfo.id,
  userId: userInfo.userId,
  deptIds: accountInfo.dept_ids || [],
  type: accountInfo.type,
  userName: userInfo.userName || accountInfo.user_name,
  displayName: accountInfo.display_name,
  avatar: userInfo.userLogo || accountInfo.avatar,
  gender: accountInfo.gender,
  mobile: accountInfo.mobile,
  phone: accountInfo.phone,
  email: userInfo.email,
  personalStatus: accountInfo.personal_status,
  deleted: accountInfo.deleted,
  version: accountInfo.version,
  isAnonymous: userInfo.userChannel === 'ANONYMOUS',
  profile: userInfo.profile,
  userNumberId: userInfo.userNumberId,
  device: userInfo.device,
  teamId: accountInfo.team_id,
});

const getInitialState = (): AuthState => ({
  ...initialState,
});

export function AuthProvider({ children }: PropsWithChildren<unknown>) {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState, getInitialState);
  const login = useEvent(async () => {
    try {
      if (window.location.hash) {
        const fullHash = window.location.hash;
        const hashWithoutSymbol = fullHash.slice(1);

        const { data: { data: token } } = await parseCode(hashWithoutSymbol);

        Cookies.set(import.meta.env.VITE_COOKIE_SUFFIX as string, token, {
          expires: 30,
          domain: '.felo.me',
        });
        getCurrentPlan();
        // 使用 pushState 或 replaceState 修改浏览器历史记录
        navigate({
          hash: '',
          search: '',
        }, {
          replace: true,
        });
      }
      dispatch({ type: ActionKind.SET_LOADING, payload: true });
      const { data } = await getUserInfo();
      if (data.status === 200) {
        dispatch({ type: ActionKind.IS_LOGIN, payload: true });
        dispatch({ type: ActionKind.SET_LOADING, payload: false });
        dispatch({
          type: ActionKind.LOGIN,
          payload: {
            user: data.data,
          },
        });

        useUserStore.getState().login(data.data);
      } else {
        // localStorage.removeItem("token");
        dispatch({ type: ActionKind.IS_LOGIN, payload: false });
        dispatch({ type: ActionKind.SET_LOADING, payload: false });
      }
    } catch (error) {
      // localStorage.removeItem("token");
      dispatch({ type: ActionKind.IS_LOGIN, payload: false });
      dispatch({ type: ActionKind.SET_LOADING, payload: false });
    } finally {
      // dispatch({ type: ActionKind.SET_LOADING, payload: false });
    }
  });

  const logout = useEvent(() => {
    window.sessionStorage.removeItem('token');
    window.sessionStorage.removeItem('teamId');

    useUserStore.getState().logout();

    dispatch({ type: ActionKind.LOGOUT });
  });

  const skiplogin = () => {
    const url = new URL(`${VITE_FELO_ID_URL}/login`);
    url.searchParams.append('app_id', 'subtitle');
    url.searchParams.append('redirect_uri', window.location.origin);

    window.location.href = url.toString();
  };

  useEffect(() => {
    login();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{ state, dispatch, login, logout, skiplogin }}
    >
      {children}
    </AuthContext.Provider>
  );
}
