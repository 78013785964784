export function copyText(text, callback) {
  // text: 要复制的内容， callback: 回调
  const tag = document.createElement('textarea');
  tag.setAttribute('id', 'cp_hgz_input');
  tag.setAttribute('readOnly', 'readOnly');
  tag.value = text;
  document.getElementsByTagName('body')[0].appendChild(tag);
  document.getElementById('cp_hgz_input').select();
  document.execCommand('copy');
  document.getElementById('cp_hgz_input').remove();
  if (callback) {
    callback(text);
  }
}
