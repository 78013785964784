import trackEvent from './tracking';

// 尝试购买产品
export const onReportPayment = (product_id: string) => (
  trackEvent({
    event_type: 'Payment',
    trigger_type: 'Automatic',
    trigger_control: 'PaymentPay',
    data: {
      product_id,
    },
  })
);

// 购买成功/失败
export const onReportPaymentEnd = (status: boolean, product_id: string) => (
  trackEvent({
    event_type: 'Payment',
    trigger_type: 'Automatic',
    trigger_control: status ? 'PaymentSucceed' : 'PaymentFailed',
    data: {
      product_id,
    },
  })
);

// 页面展示
export const onReportPageShow = (pageName: string) => (
  trackEvent({
    event_type: 'PageShow',
    trigger_type: 'Automatic',
    trigger_control: `PageShow${pageName}`,
  })
);
