import { useContext } from 'react';
import { AuthContext } from '@/components/AuthProvider/Context';

export const useAuthContext = () => useContext(AuthContext);

export const useAuthState = () => useContext(AuthContext).state;

export const useAuthUserInfo = () => {
  const state = useAuthState();

  if (!state.user) {
    throw new Error('User is not logged in');
  }

  return state.user;
};
