import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Routers from './Routes';
import { AntdProvider } from './components/base/AntdProvider';
import { AuthProvider } from './components/AuthProvider/Provider';

const queryClient = new QueryClient();

function App() {
  return (
    <AntdProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AuthProvider>
            <Routers />
          </AuthProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </AntdProvider>
  );
}

export default App;
