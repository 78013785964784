import axios from 'axios';
import Cookies from 'js-cookie';

const instance = axios.create({
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  (config) => {
    // 处理重复请求
    const token = Cookies.get(import.meta.env.VITE_COOKIE_SUFFIX as string);

    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = token;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

export const getProductList = () =>
  instance
    .request<API.Response<API.ProductListResponse>>({
      method: 'get',
      url: '/api/product/list',
    })
    .then((res) => {
      const { product_list, product_price_list, product_item_list } = res.data.data;

      return {
        ...res.data,
        data: product_list.map((product) => ({
          ...product,
          price_list: product_price_list.filter((price) => price.product_id === product.product_id),
          product_item: product_item_list.find((items) => items.product_id === product.product_id),
        })),
      };
    });

export const createOrder = (product_id: string, currency: string) =>
  instance.request<API.Response<string>>({
    method: 'POST',
    url: '/api/checkout',
    data: {
      cancel_url: `${window.location.origin}/pricing/?payment=failed&product_id=${product_id}`,
      currency,
      product_id,
      success_url: `${window.location.origin}/pricing/?payment=succeed&product_id=${product_id}`,
    },
  });

export const completeOrder = (checkout_id: string, receipt: string) =>
  instance.request<API.Response<unknown>>({
    method: 'PUT',
    url: `/api/checkout/${checkout_id}`,
    params: {
      receipt,
    },
  });

export const cancelOrder = (checkout_id: string) =>
  instance.request<API.Response<unknown>>({
    method: 'DELETE',
    url: `/api/checkout/${checkout_id}`,
  });

// 当前余额
export const getCurrentPlan = () =>
  instance.request<API.Response<API.PlanResponse>>({
    url: '/api/plan/current',
  });

// 当前购买/赠送余额
export const getPlanBalance = () =>
  instance.request<API.Response<API.PlanBalanceResponse>>({
    url: '/api/plan/balance',
  });

// 购买记录
export const getPlanList = () =>
  instance.request<API.Response<API.PlanListResponse>>({
    url: '/api/plan/detail.list',
  });

export const getBoughtProductList = (current: number, size: number) =>
  instance.request<API.Response<API.BoughtProductResponse>>({
    url: '/api/user/product/page',
    params: {
      current,
      size,
    },
  });

// 购买记录
export const getUsageList = (current: number, size: number) =>
  instance.request<API.Response<API.DeductionResponse>>({
    url: '/api/deduction/page',
    params: {
      current,
      size,
    },
  });

// 用户设置
// Todo: 已经废弃了
export const getUserConfig = (key?: string) =>
  instance
    .request<API.Response<API.UserConfigResponse>>({
      url: '/api/user/config',
      params: {
        key,
      },
    })
    .then((res) => {
      if (res.data.data) {
        Object.keys(res.data.data).forEach((k) => {
          // @ts-ignore
          res.data.data[k] = JSON.parse(res.data.data[k]).value;
        });
      }

      return res;
    });

// 修改字幕设置
// Todo: 已经废弃了
export const updateUserConfig = (key: string, data: any) =>
  instance.request<API.Response<unknown>>({
    method: 'POST',
    url: '/api/user/config/set',
    data: {
      config_type: key,
      data: JSON.stringify(data),
    },
  });

// 邀请码
export const getInviteCode = () =>
  instance.request<API.Response<string>>({
    url: '/api/user/invitation/code',
  });

// 查询用户信息
export const getUserName = (code: string) =>
  instance.request<API.Response<string>>({
    url: `/api/user/invitation/code/username?code=${code}`,
  });

// 查询分页邀请记录
export const getInvitationPage = (current: number, size: number) =>
  instance.request<API.Response<API.InviteListResponse>>({
    url: '/api/user/invitation/page',
    params: {
      current,
      size,
    },
  });

// 查询邀请信息
export const getInvitationInfo = () =>
  instance.request<API.Response<API.InviteInfo>>({
    url: '/api/user/invitation',
  });

// 用户字幕块查询
export const getLogDetail = (id: string) =>
  instance.request<API.Response<API.LogDetailResponse>>({
    url: `/api/user/subtitle/segment/${id}`,
  });

// 用户字幕块分页查询
export const getLogPage = (current: number, size: number) =>
  instance.request<API.Response<API.LogListResponse>>({
    url: '/api/user/subtitle/segment/page',
    params: {
      current,
      size,
    },
  });

// 导出字幕
export const downloadLog = (data: { user_subtitle_segment_id: string; zone: string }) =>
  instance.request<API.Response<string>>({
    method: 'PUT',
    url: '/api/user/subtitle/segment/export',
    data,
  });

// 删除字幕
export const deleteLog = (id: string) =>
  instance.request<API.Response<unknown>>({
    method: 'DELETE',
    url: `/api/user/subtitle/segment/${id}`,
  });

// 用户字幕标题修改
export const updateLogTitle = (data: { user_subtitle_segment_id: string; title: string }) =>
  instance.request<API.Response<unknown>>({
    method: 'PUT',
    url: '/api/user/subtitle/segment/title',
    data,
  });

export default instance;
