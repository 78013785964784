import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {  Dropdown, Menu,Drawer } from 'antd';
import UserMenu from '../../dashboard/header/userMenu';
import FeloLogo from '@/assets/images/image 6.svg';
import Smallmenu from "@/assets/images/smallmenu.svg";
import Arrow from "@/assets/images/Arrow.svg";
import Close from "@/assets/images/close.svg"
import type { ItemType } from 'antd/lib/menu/hooks/useItems';

import './index.less';
const { Item, SubMenu } = Menu;

function Header() {
  const { t } = useTranslation();
  const [productVisible, setProductVisible] = useState(false)
  const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
  const menuItems: ItemType[] = [
    {
      key: 'menutranslator',
      label: (
        <div className="subtitle-menu-item">
          <a className="menu-item-name" target="_blank" href={import.meta.env.VITE_TRANSLATOR_URL} >{t('menutranslator')}</a>
        </div>
      ),
    },
    {
        key: 'menumeet',
        label: (
          <div className="subtitle-menu-item">
            <a className="menu-item-name" target="_blank" href={import.meta.env.VITE_FELO_MEET} >{t('menumeet')}</a>
          </div>
        ),
      },
      {
        key: 'menusubtitle',
        label: (
          <div className="subtitle-menu-item">
            <a className="menu-item-name" target="_blank" href={import.meta.env.VITE_FELO_SUBTITLES} >{t('menusubtitle')}</a>
          </div>
        ),
      },
      {
        key: 'menuim',
        label: (
          <div className="subtitle-menu-item">
            <a className="menu-item-name" target="_blank" href={import.meta.env.VITE_FELO_IM} >{t('menuim')}</a>
          </div>
        ),
      },
      {
        key: 'menuglarity',
        label: (
          <div className="subtitle-menu-item">
            <a className="menu-item-name" target="_blank" href='https://glarity.app/' >{t('menuglarity')}</a>
          </div>
        ),
      },
      {
        key: 'menucobol',
        label: (
          <div className="subtitle-menu-item">
            <a className="menu-item-name" target="_blank" href='https://cobol.felo.ai/' >{t('menucobol')}</a>
          </div>
        ),
      },
  ];
  const openpricingPage = () => {
    window.location.href = '/pricing';
  }
  return (
    <header className="home-menu">
      <div className="wrap">
        <Link className="logo-text" to="/">
          <img className="logo-icon" src={FeloLogo} />
          {t('menusubtitle')}
        </Link>
        <div className="title-right">
          <Link className="text-menu" to="/pricing">
            {t('Pricing')}
          </Link>
          <UserMenu />
        </div>
      </div>
        {/* <div className='menu-items'>
                <Dropdown overlay={<Menu items={menuItems} />}  visible={productVisible}
                    onVisibleChange={setProductVisible} overlayClassName="dropdown-custom" placement="bottomRight">
                    <div  className='globalmenuItem'>
                        {t('menuproduct')}
                        <img src={Arrow} className='arrowicon' />
                    </div>
                </Dropdown>

                <div className="globalmenuItem" onClick={openpricingPage}>
                    {t('Pricing')}
                </div>
                <UserMenu />
                <div onClick={showDrawer} className='smallmenuItem'>
                    <img src={Smallmenu} className='smallicon'/>
                </div>
            </div>
        </div>
        <Drawer title=""  closeIcon={false} extra={<img src={Close} onClick={onClose} className='arrowicon' />} width={260}  onClose={onClose} bodyStyle={{padding:'0px'}} visible={open} placement='left'>
                <Menu mode="inline"  className='custom-menus' >
                    <SubMenu key='product' title={t('menuproduct')} >
                            {menuItems?.map((child) => (
                                <Item key={child?.key} >
                                    {child?.label}
                                </Item>
                            ))}
                    </SubMenu>
                    <Item key='Pricing'  onClick={openpricingPage}> {t('Pricing')}
                    </Item>
                </Menu>
            </Drawer> */}
    </header>
  );
}

export default Header;
