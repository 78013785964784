import Header from '@/components/layout/front/header';
import Footer from "../Footer/footer"
import PricingContent from './newPricing';

function Pricing() {
  return (
    <div className='login-background'>
      <Header />
      <PricingContent />
      <Footer />
    </div>
  );
}

export default Pricing;
