// 根据浏览器语言获取对应的货币
export const getCurrency = (defaultLang: string) => {
  const lang = (defaultLang || navigator.language).toLocaleLowerCase();

  if (lang === 'zh-cn') {
    return 'CNY';
  }
  if (lang === 'zh-tw' || lang.includes('tw') || lang.includes('hk') || lang.includes('zh-mo')) {
    return 'TWD';
  }
  if (lang === 'ja-jp' || lang.includes('ja')) {
    return 'JPY';
  }

  return 'USD';
};

interface ICurrencyMapItem {
  prefix?: string;
  suffix?: string;
}

const currencyInfoMap: Record<string, ICurrencyMapItem> = {
  CNY: {
    prefix: 'RMB ',
  },
  JPY: {
    suffix: ' 円',
  },
  TWD: {
    prefix: 'NT$',
  },
  USD: {
    prefix: '$',
    suffix: ' USD',
  },
};

// 根据货币单位格式化
export const getPrice = (value: number, currency: string) => {
  const matchItem = currencyInfoMap[currency];

  const prefixStr = matchItem.prefix || '';
  const suffixStr = matchItem.suffix || '';

  if (matchItem) {
    return `${prefixStr}${Intl.NumberFormat().format(value)}${suffixStr}`;
  }

  return `${currency} ${value}`;
};
