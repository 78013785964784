import React, { useState ,useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import languageIcon from "@/assets/images/language.svg"
import "./index.less"
const LanguageSelector = ({ }) => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState('');
    const languageClick =(language:string)=>{
      i18n.changeLanguage(language);
      const text = getCurrLanguage(language)
      setLanguage(text)
    }
    const getCurrLanguage = (language:string)=>{
      switch (language) {
        case 'ja':
          return t('ja_JP');
        case 'en':
          return t('en_US');
        case 'zh-CN':
          return t('zh_CN');
        case 'zh-TW':
          return t('zh_TW');
        default:
          return '';
      }
    }
    useEffect(() => {
      const text = getCurrLanguage(i18n.resolvedLanguage)
      setLanguage(text)
    }, []);
  return (
    <div className="language-selector">
      <div className="popover">
        <div className="popover-content">
              <p className='popover-content-text' onClick={()=>languageClick('ja')}>{t('ja_JP')}</p>
              <p className='popover-content-text' onClick={()=>languageClick('en')}>{t('en_US')}</p>
              <p className='popover-content-text' onClick={()=>languageClick('zh-CN')}>{t('zh_CN')}</p>
              <p className='popover-content-text' onClick={()=>languageClick('zh-TW')}>{t('zh_TW')}</p>
        </div>
        <img src={languageIcon} className='language-icon'/>
        <div>{language}</div>
        </div>
    </div>
  );
};

export default LanguageSelector;
