import { Route, Routes } from 'react-router-dom';

import Home from './pages/home';
import Pricing from './pages/pricing';
import Invite from './pages/invite';
// import Bill from './pages/bill';
// import Account from './pages/account';
import Uninstall from './pages/uninstall';
import LoginPopupPage from './pages/CallBack';
// import PrivateRoute from '@/components/AuthProtection/PrivateRoute';
import { MainLayout } from '@/pages/layouts/MainLayout';
// import Log from './pages/log';
// import Detail from './pages/log/detail';
// import User from './pages/account/components/user';

function RouterContainer() {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route
          path="/"
          element={
            <Home />
          }
        />
        {/* 已废弃 */}
        {/* <Route
          path="/bill"
          element={
            <PrivateRoute>
              <Bill />
            </PrivateRoute>
          }
        /> */}

        {/* 已废弃 */}
        {/* <Route
          path="/account"
          element={
            <PrivateRoute>
              <Account />
            </PrivateRoute>
          }
        >
          <Route path="user" element={<User />} />
          <Route path="log" element={<Log />} />
          <Route path="log/:id" element={<Detail />} />
        </Route> */}
        {/* <Route path="/account" element={<Navigate to="/account/user" />} /> */}
        <Route
          path="/uninstall"
          element={
            <Uninstall />
          }
        />
        <Route
          path="/pricing"
          element={
            <Pricing />
          }
        />
        <Route
          path="/invite"
          element={
            <Invite />
          }
        />
        <Route path="/login_popup_callback" element={<LoginPopupPage />} />
      </Route>
    </Routes>
  );
}

export default RouterContainer;
