import { useEffect } from 'react';
import { getCurrentPlan } from '@/api';

function closePage() {
  if (navigator.userAgent.indexOf('Firefox') !== -1 || navigator.userAgent.indexOf('Chrome') !== -1) {
    window.location.href = 'about:blank';
    window.close();
  } else {
    window.opener = null;
    window.open('', '_self');
    window.close();
  }
}

function LoginPopupPage() {
  const getCurrent = () => {
    try {
      getCurrentPlan();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getCurrent();
    closePage();
  }, []);

  return null;
}

export default LoginPopupPage;
