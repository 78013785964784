import Header from '@/components/layout/front/header';
import './index.less';

function UnInstall() {
  return (
    <div className="login-background">
      <Header />
      <div className="ifram-content">
        <iframe
          className="ifram-div"
          src="https://docs.google.com/forms/d/e/1FAIpQLSfpPqlzQyTSmReJzqI3Uq4MYn1aFVLcIJT0Pf8WFxsbNMEHQw/viewform?embedded=true"
        >
          Loading…
        </iframe>
      </div>
    </div>
  );
}

export default UnInstall;
