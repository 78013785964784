import { useEffect, useState } from 'react';
import { Spin, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import Decimal from 'decimal.js';
import { useAuthContext } from '@/hooks/auth';
import { cancelOrder, completeOrder, createOrder, getProductList } from '@/api';
import { getCurrency, getPrice } from '@/utils/price';
import { onReportPayment, onReportPaymentEnd } from '@/utils/report';
import TelIcon from '@/assets/images/tel.svg';
import PhoneIcon from '@/assets/images/phone.svg';

import './newIndex.less';

const icons = ['😀', '🤩', '🎉', '👑'];
const productName = ['minuteExperiencePack', 'minuteProfessionPack', 'minuteEnjoyPack', 'minuteDeluxePack'];

function PricingContent() {
  const navigate = useNavigate();
  const { state: { isLogin }, skiplogin } = useAuthContext();
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const getProductRequest = useQuery({
    queryKey: ['getPricingProduct'],
    queryFn: getProductList,
  });

  useEffect(() => {
    const searchObj = new URLSearchParams(location.search);
    const type = searchObj.get('payment');
    const productId = searchObj.get('product_id');
    const checkoutId = searchObj.get('checkout_id');
    const receipt = searchObj.get('receipt');

    if (!productId) {
      return;
    }

    if (type === 'succeed' && checkoutId && receipt) {
      completeOrder(checkoutId, receipt);

      message.success(t('paySucceed'));

      window.setTimeout(() => {
        window.location.href = `${import.meta.env.VITE_FELO_USER_URL}/account`;
      }, 5000);
    } else if (type === 'failed' && checkoutId) {
      cancelOrder(checkoutId);
    }

    onReportPaymentEnd(type === 'succeed', productId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const [processingProductId, setProcessingProductId] = useState('');

  const onClickBuy = (product_id: string, currency: string) => {
    if (processingProductId) {
      return;
    }

    onReportPayment(product_id);

    if (isLogin) {
      setProcessingProductId(product_id);

      createOrder(product_id, currency).then((data) => {
        if (data.data) {
          window.location.href = data.data.data;
        }
      });
    } else {
      skiplogin();
    }
  };

  return (
    <main className="pricing-new-content">
      <section className="pricing-new-header">
        <div className="wrap">
          <h1 className="pricing-title">{t('feloSubtitlesDesc')}</h1>
        </div>
      </section>
      <section className="pricing-data-content">
        <div className="wrap">
          <div className="pricing-data-title">{t('packagePrice')}</div>
          {getProductRequest.isLoading && <Spin size="large" />}
          <div className="pricing-data-items" hidden={getProductRequest.isLoading}>
            {getProductRequest.data?.data.map((item, index) => {
              let minutes = item.product_item?.total;
              minutes = minutes ? new Decimal(minutes).div(60).ceil().toNumber() : 0;

              const currency = getCurrency(i18n.language);
              const { price, original_price } = item.price_list.find((item) => item.currency === currency) || {};

              return (
                <div key={item.product_id} className="pricing-card">
                  <span className="card-icon">
                    {icons[index]}
                  </span>
                  <h3 className="card-time">
                    {t('minutes', { time: minutes })}
                    {t(productName[index])}
                  </h3>
                  <p className="card-price">
                    {price && getPrice(price, currency)}
                  </p>
                  <p className="card-original-price">
                    {t('originalCost')} {original_price && getPrice(original_price || 0, currency)}
                  </p>
                  <p className="card-validity">
                    {t('periodOfValidity', { time: item.expiry_value })}
                  </p>
                  <button className="item-button" onClick={() => onClickBuy(item.product_id, currency)}>
                    {processingProductId === item.product_id ? <LoadingOutlined /> : t('buyNow')}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className="pricing-contact">
        <div className="wrap">
          <h3>{t('priceDescription')}</h3>
          <ol>
            <li>{t('priceDescriptionOne')}</li>
            <li>{t('priceDescriptionTwo')}</li>
            <li>{t('contactUs')}</li>
          </ol>
          <div className="tel-content">
            <img className="tel-icon" src={TelIcon} />
            <div className="tel-text">03-3527-2828</div>
          </div>
          <div className="tel-content" style={{ marginBottom: '13vh' }}>
            <img className="tel-icon" src={PhoneIcon} />
            <div className="tel-text">support<span>-from-front</span>@sparticle.com</div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default PricingContent;
